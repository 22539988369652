import { defineNuxtPlugin } from 'nuxt/app'

import { DEFAULT_OPTIONS } from '~/src/constants/common'
import { getSubscriptionRCPByACF } from '~/constants/memberships'
import { MEMBERSHIPS } from '~/src/constants/wordpress-content'

import type { TGeneral } from '~/src/types/common'
import { useMembershipsStore } from '~/stores/memberships'

export default defineNuxtPlugin((nuxtApp) => {
  const { $httpClient, $config, $cookies }: TGeneral = nuxtApp
  const membershipsStore = useMembershipsStore()

  /**
   * Check if a subscription is active
   */
  const hasActiveMembershipFor = async (subscription: string) => {
    const isActive = await $httpClient.post(
      `${$config.public.apiRestEndpoint}/membership/status/category/${subscription}`,
    )
    membershipsStore.setMembershipStatus({ category: subscription, isActive })

    return isActive
  }

  /**
   * Get active subscriptions from the server
   */
  const getActiveMemberships = async (force = true) => {
    const savedMembership = $cookies.get(MEMBERSHIPS)

    if (!force && savedMembership) {
      membershipsStore.setActiveMembershipStatuses(savedMembership as string[])
      return savedMembership
    }

    const memberships = await $httpClient.post(
      `${$config.public.apiRestEndpoint}/users/memberships`,
    )

    const activeMemberships = [
      ...new Set(
        memberships?.map((_membership) => getSubscriptionRCPByACF(_membership)),
      ),
    ]

    $cookies.set(
      MEMBERSHIPS,
      JSON.stringify(activeMemberships),
      DEFAULT_OPTIONS,
    )

    membershipsStore.setActiveMembershipStatuses(activeMemberships as string[])
    return activeMemberships
  }

  /**
   * Add active memberships to the $store on a page load
   */
  const restoreMemberships = () => {
    const activeMemberships = $cookies.get(MEMBERSHIPS)

    if (!activeMemberships) return

    membershipsStore.setActiveMembershipStatuses(activeMemberships)
  }

  return {
    provide: {
      membershipApi: {
        hasActiveMembershipFor,
        getActiveMemberships,
        restoreMemberships,
      },
    },
  }
})
