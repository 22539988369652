import { v4 as uuidv4 } from 'uuid'
import { defineNuxtPlugin } from 'nuxt/app'
import loginGql from '~/queries/login.gql'
import { getAdaptedUser } from '~/src/adapters/auth'
import useAuthStore from '~/stores/auth'
import { GraphQlProxy } from '~/src/utils/graphql-proxy'
import {
  BACKEND_VERSION_COOKIE,
  DEFAULT_OPTIONS,
  JWT_ACCESS_TOKEN,
  REFRESH_TOKEN,
  USER_INFO,
} from '~/src/constants/common'
import type { TLoginPayload, TLoginResponse } from '~/src/types/auth'
import type { TGeneral } from '~/src/types/common'
import { MEMBERSHIPS } from '~/src/constants/wordpress-content'
import { useMembershipsStore } from '~/stores/memberships'

/**
 * This is a client to handle all the authentication flow with graphql
 *
 * @method login
 * @method register
 */
export default defineNuxtPlugin({
  name: 'bmAuth',
  dependsOn: ['cookies', 'axios'],
  setup(nuxtApp) {
    const {
      $cookies,
      $config,
      $axios,
      $graphql,
      $hubspotIdentity,
      $hubspotLiveChat,
      $rudderstack,
    }: TGeneral = nuxtApp
    const authStore = useAuthStore()
    const membershipsStore = useMembershipsStore()
    const jwtToken = useCookie(JWT_ACCESS_TOKEN)

    const apiRestEndpoint = $config.public.apiRestEndpoint
    const isStellateEnabled = $config.public.isStellateEnabled

    const authenticatedClient = new GraphQlProxy({
      graphQLClient: $graphql.authenticatedClient,
      $cookies,
      $config,
      options: { maxNumberOfRetries: 1 },
    })

    const unAuthenticatedClient = new GraphQlProxy({
      graphQLClient: $graphql.default,
      $cookies,
      $config,
    })

    const defaultClient = isStellateEnabled
      ? unAuthenticatedClient
      : authenticatedClient

    const logout = () => {
      $rudderstack.trackLogOut()

      authStore.clearSession()
      membershipsStore.clearKeys()

      $cookies.remove(JWT_ACCESS_TOKEN)
      $cookies.remove(REFRESH_TOKEN)
      $cookies.remove(USER_INFO)
      $cookies.remove(MEMBERSHIPS)
      $hubspotLiveChat.removeLiveChat()
    }

    const login = async (form: TLoginPayload) => {
      const response = await authenticatedClient.mutate({
        mutation: loginGql,
        variables: {
          ...form,
          clientMutationId: uuidv4(),
        },
      })

      const login: TLoginResponse = response?.data?.login

      const { authToken, refreshToken, user } = login
      const adaptedUser = getAdaptedUser(user)

      $cookies.set(JWT_ACCESS_TOKEN, authToken, DEFAULT_OPTIONS)
      $cookies.set(REFRESH_TOKEN, refreshToken, DEFAULT_OPTIONS)
      $cookies.set(USER_INFO, adaptedUser, DEFAULT_OPTIONS)
      $cookies.set(
        BACKEND_VERSION_COOKIE,
        await getBackendVersion(),
        DEFAULT_OPTIONS,
      )

      $hubspotIdentity.identifyUser()
      $hubspotLiveChat.initLiveChat(adaptedUser.email)

      authStore.setJwtToken(authToken)
      authStore.setUser(adaptedUser)

      $rudderstack.trackLogIn()
      return response.data
    }

    const isAuthenticated = () => {
      return !!jwtToken.value
    }

    /**
     * Get a graphql client based on the authentication status of the client
     *
     * For unauthenticated users, they will use the defaultClient. It uses a GET request for all queries
     * For authenticated users, they will use authenticatedClient. It uses a POST request, so won't care about the LightSpeed GET caching system
     *
     * @returns an graphql client
     */
    const getGraphqlClient = () => {
      return isAuthenticated() ? authenticatedClient : defaultClient
    }

    /**
     * Client to get information that doesn't require authentication. It will use GET request
     * to take advantage of the GET caching server system
     *
     * @returns a graphql client that uses GET request
     */
    const getPublicGraphqlClient = () => defaultClient

    const getAuthenticatedClient = () => authenticatedClient

    /**
     * This endpoint is added here because of it is required by this plugin, and the
     * order of the plugin imports matters, and restClient cannot be imported before
     * this plugin because of other plugin dependencies.
     */
    const getBackendVersion = async (): Promise<number> => {
      const response = await $axios.get(`${apiRestEndpoint}/backend-version`)
      return response?.data?.version || 0
    }

    return {
      provide: {
        bmAuth: {
          login,
          logout,
          isAuthenticated,
          getGraphqlClient,
          getPublicGraphqlClient,
          getAuthenticatedClient,
          getBackendVersion,
        },
      },
    }
  },
})
