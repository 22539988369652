import type { TScript } from '../types/nuxt-config'
import type { TCommon } from '../types/common'
import { COOKIE_SCRIPT_KEY } from '../enums/cookie-script.enum'

export const cookieScriptConfiguration = (
  id: string,
  isProduction: boolean,
): TScript | null => {
  if (!id && isProduction) {
    throw new Error('Env COOKIE_SCRIPT_ID is required')
  }

  if (id) {
    return {
      src: `https://geo.cookie-script.com/s/${id}.js?country=gb-br&state=ca&region=eu`,
      charset: 'UTF-8',
      async: true,
      id: 'cookie-script-loader',
      type: 'text/javascript',
    }
  }

  return null
}

export const acceptCookieByCategory = (
  $cookiesInstance: TCommon,
  category: string,
): boolean => {
  const cookieScriptConsentValue = $cookiesInstance.get(COOKIE_SCRIPT_KEY)
  let cookieIsAllowed: boolean

  if (!cookieScriptConsentValue) {
    cookieIsAllowed = true
  } else if (cookieScriptConsentValue && cookieScriptConsentValue.categories) {
    cookieIsAllowed = cookieScriptConsentValue.categories.includes(category)
  } else {
    cookieIsAllowed = false
  }

  return cookieIsAllowed
}
