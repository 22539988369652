import { type LoadOptions, RudderAnalytics } from '@rudderstack/analytics-js'
import useAuthStore from '~/stores/auth'
import debounce from 'lodash/debounce'
import type {
  RSDownloadEvent,
  RSFilterType,
  RSFormEvent,
  RSVideoEvent,
} from '~/types/rudderstack.type'

export default defineNuxtPlugin((nuxtApp) => {
  const authStore = useAuthStore()
  const { $config } = useNuxtApp()

  const DATA_PLANE_URL = $config.public.rudderstack.dataPlaneUrl
  const WRITE_KEY = $config.public.rudderstack.writeKey
  const OPTIONS = {
    logLevel: 'DEBUG',
    onLoaded: () => {
      if (authStore.authenticated) {
        identifyUser()
      }
    },
  } satisfies LoadOptions

  const rudderAnalytics = new RudderAnalytics()

  rudderAnalytics.load(WRITE_KEY, DATA_PLANE_URL, OPTIONS)

  const getUserData = () => {
    if (!authStore.authenticated || !authStore.user) return {}

    const { databaseId, name, firstName, lastName, email, company } =
      authStore.user
    return {
      email_encrypted: btoa(email),
      wordpress_id: databaseId,
      email,
      name,
      firstName,
      lastName,
      company,
    }
  }

  const getBaseParams = () => {
    return {
      page_title: window.document.title,
      page_url: useRoute().path,
      uri:
        window.location.pathname +
        window.location.search +
        window.location.hash,
      submission_time: new Date(),
      platform: 'www',
    }
  }

  /**
   * Track user log in
   */
  const trackLogIn = () => {
    const data = getUserData()
    rudderAnalytics.track('logged_in', data)

    identifyUser()
  }

  /**
   * Track user log out
   */
  const trackLogOut = () => {
    const data = getUserData()
    rudderAnalytics.track('logged_out', {
      ...data,
      ...getBaseParams(),
    })
  }

  /**
   * Track page views. We debounce the event to avoid sending too many events
   * to RudderStack. Specifically, in the pages with tabs, where the event
   * was triggering twice.
   */
  const trackPageView = debounce(() => {
    const data = {
      ...getBaseParams(),
      ...getUserData(),
    }

    rudderAnalytics.track('page_viewed', data)
  }, 1000)

  /**
   * Track form submissions
   */
  const trackFormSubmissions = (formData: RSFormEvent) => {
    const data = getUserData()

    rudderAnalytics.track('form_submitted', {
      ...data,
      ...formData,
      ...getBaseParams(),
    })
  }

  /**
   * Track form start event in a form
   */
  const trackFormStart = (formData: RSFormEvent) => {
    const data = getUserData()

    rudderAnalytics.track('form_start', {
      ...data,
      ...formData,
      ...getBaseParams(),
    })
  }

  /**
   * Track form field changes in a form
   */
  const trackFormFieldChange = debounce(
    (formFieldData: {
      form_id: string | number
      form_name: string
      form_type: string
      field_name: string
      field_type: string
      field_value?: string | any
    }) => {
      const data = getUserData()
      rudderAnalytics.track('field_changed', {
        ...formFieldData,
        ...data,
        ...getBaseParams(),
      })
    },
    500,
  )

  /**
   * Track video ended
   */
  const trackVideoEnded = (videoData: RSVideoEvent) => {
    const data = getUserData()
    rudderAnalytics.track('video_completed', {
      ...data,
      ...videoData,
      ...getBaseParams(),
    })
  }

  /**
   * Track video started
   */
  const trackVideoStarted = (videoData: RSVideoEvent) => {
    const data = getUserData()
    rudderAnalytics.track('video_started', {
      ...data,
      ...videoData,
      ...getBaseParams(),
    })
  }

  /**
   * Track video progress. Debounced to avoid sending too many events
   * to RudderStack.
   *
   * The `progress` event from the vimeo player is not always consistent in the
   * time it takes to fire the event. That's why we debounce the event to 7 seconds
   * to avoid sending events when the user is still watching the video.
   */
  const trackVideoProgress = debounce((videoData: RSVideoEvent) => {
    const data = getUserData()
    rudderAnalytics.track('video_progress_made', {
      ...data,
      ...videoData,
      ...getBaseParams(),
    })
  }, 7000)

  /**
   * Track error shown in the website
   */
  const trackErrorShown = (errorData: {
    error_code: string | number
    error_message: string
    error_type: string
  }) => {
    const data = getUserData()
    rudderAnalytics.track('error_shown', {
      ...data,
      ...errorData,
      ...getBaseParams(),
    })
  }

  /**
   * Track downloads in the website. That includes report downloads and chart downloads
   */
  const trackDownloads = (reportData: RSDownloadEvent) => {
    const data = getUserData()
    rudderAnalytics.track('file_downloaded', {
      ...data,
      ...getBaseParams(),
      ...reportData,
      file_type: reportData.file_type || 'pdf',
    })
  }

  /**
   * Track chart interactions in the website. We debounced this function because
   * it's trigger on hover state over the chart and that event is triggered many times
   * in a short period of time.
   */
  const trackChartInteraction = debounce(
    (chartData: {
      chart_title: string
      chart_type: string
      start_date?: string
      end_date?: string
      current_filters?: object
    }) => {
      const data = getUserData()

      rudderAnalytics.track('chart_interaction', {
        ...data,
        ...getBaseParams(),
        ...chartData,
      } as any)
    },
    2000,
  )

  const trackFilterApplied = debounce(
    (eventData: {
      filter_values: object
      filter_type: RSFilterType
      chart_title: string
    }) => {
      const data = getUserData()

      rudderAnalytics.track('filter_applied', {
        ...data,
        ...getBaseParams(),
        ...eventData,
      } as any)
    },
    1000,
  )

  /**
   * Track chart interactions in the website
   */
  const trackChartDownload = (
    chartData: {
      chart_title?: string
      chart_type?: string
      element_id?: string
      download_format?: 'png' | 'xlsx'
    } = { chart_type: 'lines' },
  ) => {
    const data = getUserData()
    rudderAnalytics.track('chart_download', {
      ...data,
      ...chartData,
      ...getBaseParams(),
    } as any)
  }

  /**
   * Track content share in the website
   */
  const trackContentShare = (shareData: {
    share_method: 'twitter' | 'linkedin' | 'link'
    content: object
  }) => {
    const data = getUserData()

    rudderAnalytics.track('content_share', {
      ...data,
      ...shareData,
      ...getBaseParams(),
      content_type: 'index',
    } as any)
  }

  /**
   * Track search done in the website
   */
  const trackSearchDone = (searchData: {
    search_query: string
    search_results_count: number
    search_type: string
  }) => {
    const data = getUserData()

    rudderAnalytics.track('search_done', {
      ...data,
      ...searchData,
      ...getBaseParams(),
    })
  }

  /**
   * Track newsletter subscription
   */
  const trackNewsletterSubscription = (newsletterData: {
    newsletter_id: string | number
    newsletter_name: string
  }) => {
    rudderAnalytics.track('newsletter_subscribed', {
      ...getUserData(),
      ...newsletterData,
      ...getBaseParams(),
    })
  }

  const trackContentGatedInteraction = debounce(() => {
    rudderAnalytics.track('content_gated_interaction', {
      ...getUserData(),
      ...getBaseParams(),
    })
  }, 500)

  /**
   * Identify the user in RudderStack
   */
  const identifyUser = () => {
    const data = getUserData()
    rudderAnalytics.identify(data.email_encrypted as any, data)
    rudderAnalytics.track('identified', { ...data, ...getBaseParams() })
  }

  /**
   * Hook to track page views
   */
  nuxtApp.hook('page:finish', () => {
    trackPageView()
  })

  return {
    provide: {
      rudderstack: {
        trackPageView,
        trackLogIn,
        trackLogOut,
        trackFormSubmissions,
        trackFormStart,
        trackFormFieldChange,
        trackVideoEnded,
        trackVideoStarted,
        trackVideoProgress,
        trackDownloads,
        trackChartDownload,
        trackChartInteraction,
        trackFilterApplied,
        trackContentShare,
        trackSearchDone,
        trackNewsletterSubscription,
        trackContentGatedInteraction,
        trackErrorShown,
      },
    },
  }
})
