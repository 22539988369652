import validate from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.1.0_eslint@9.16.0_jiti@2.4.2__ioredis@5.4_v4rxwth7vewgp3u3axeczg7rxa/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/opt/buildhome/repo/middleware/auth.global.ts";
import redirects_45global from "/opt/buildhome/repo/middleware/redirects.global.ts";
import ticker_45global from "/opt/buildhome/repo/middleware/ticker.global.ts";
import trailing_45slash_45global from "/opt/buildhome/repo/middleware/trailing-slash.global.ts";
import manifest_45route_45rule from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.1.0_eslint@9.16.0_jiti@2.4.2__ioredis@5.4_v4rxwth7vewgp3u3axeczg7rxa/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  redirects_45global,
  ticker_45global,
  trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  authenticated: () => import("/opt/buildhome/repo/middleware/authenticated.ts"),
  "has-active-membership": () => import("/opt/buildhome/repo/middleware/has-active-membership.ts"),
  "premium-market-guard": () => import("/opt/buildhome/repo/middleware/premium-market-guard.ts"),
  "price-dashboard": () => import("/opt/buildhome/repo/middleware/price-dashboard.ts"),
  "redirect-tab": () => import("/opt/buildhome/repo/middleware/redirect-tab.ts"),
  "refresh-live-chat": () => import("/opt/buildhome/repo/middleware/refresh-live-chat.ts")
}