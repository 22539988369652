import VueGtag from 'vue-gtag-next'
import type { TGeneral } from '~/src/types/common'
import { acceptCookieByCategory } from '~/src/utils/cookie-script'
import { COOKIE_SCRIPT_CATEGORIES } from '~/src/enums/cookie-script.enum'

export default defineNuxtPlugin({
  name: 'google-analytics',
  dependsOn: ['cookies'],
  setup(nuxtApp) {
    const { $config, $cookies, vueApp }: TGeneral = nuxtApp

    const acceptTargetingCookies = acceptCookieByCategory(
      $cookies,
      COOKIE_SCRIPT_CATEGORIES.PERFORMANCE,
    )

    vueApp.use(VueGtag, {
      isEnabled: $config.public.isProduction && acceptTargetingCookies,
      property: {
        id: $config.public.googleAnalyticsId,
      },
    })
  },
})
