import { defineStore } from 'pinia'
import * as Sentry from '@sentry/vue'
import type { TUser } from '~/src/types/auth'
import type { THubSpotUser } from '~/src/types/hubspot'

export const useAuthStore = defineStore('auth', () => {
  const jwtToken = ref<string | null>(null)
  const user = ref<TUser | null>(null)
  const _hubSpotUser = ref<THubSpotUser | null>(null)

  const hasQueryHubSpot = ref<boolean>(false)
  const hubSpotUser = computed<THubSpotUser>(() => _hubSpotUser.value)
  const authenticated = computed(() => !!jwtToken.value)
  const userRoles = computed<string[]>(() => user.value?.roles ?? [])
  const fullName = computed(() => {
    if (!user.value) return ''
    return `${user.value?.firstName} ${user.value?.lastName}`
  })

  const setJwtToken = (token: string) => {
    jwtToken.value = token
  }

  const setUser = (value: TUser) => {
    const sentryUser = value
      ? { id: value.id, email: value.email, username: value.name }
      : null
    Sentry.setUser(sentryUser)
    user.value = value
  }

  const setHubSpotUser = (value) => {
    _hubSpotUser.value = value
  }

  const clearSession = () => {
    jwtToken.value = null
    user.value = null
    _hubSpotUser.value = null
    hasQueryHubSpot.value = false
  }

  return {
    jwtToken,
    hasQueryHubSpot,
    hubSpotUser,
    user,
    authenticated,
    userRoles,
    fullName,
    clearSession,
    setJwtToken,
    setUser,
    setHubSpotUser,
  }
})

export default useAuthStore
