import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { validUnitsByProductConfig } from '~/utils/allPrices/chartBuilder/config'
import { getAllCombinationsByProduct } from '~/utils/allPrices/price-chart-builder'
import { useChartBuilderUnitsMeasurementStore } from '~/stores/chartBuilder/unitsMeasurement'

export const useChartBuilderConfigStore = defineStore(
  'chartBuilderConfig',
  () => {
    const unitsMeasurementStore = useChartBuilderUnitsMeasurementStore()
    const currency = ref<string | null>(null)
    const unit = ref<string | null>(null)
    const products = ref([
      {
        id: '',
        key: 'default-product',
        name: '',
        completed: false,
        config: null,
        valid: false,
      },
    ])

    const validProductsByUnits = computed(() => {
      const selectedUnits = unitsMeasurementStore.selectedUnits
      return products.value.map((product) => ({
        ...product,
        config: validUnitsByProductConfig(product.config, selectedUnits),
      }))
    })

    const series = computed(() => {
      return products.value
        .map((product) => getAllCombinationsByProduct(product))
        .flat().length
    })

    const isValidProducts = computed(() =>
      products.value.every((product) => product.completed),
    )

    const isEditing = computed(() =>
      products.value.some((product) => product.id !== ''),
    )

    const validateProducts = computed(() =>
      products.value.every((product) => product.completed),
    )

    const validateCurrency = computed(
      () => validateProducts.value && currency.value !== null,
    )

    const validateUnit = computed(() => {
      const hasOnlyPayable = unitsMeasurementStore.hasOnlyPayable
      const hasOnlyKWH = unitsMeasurementStore.hasOnlyKWH
      const havePayableAndKWH = unitsMeasurementStore.havePayableAndKWH

      if (hasOnlyPayable || hasOnlyKWH) return true
      if (havePayableAndKWH) return validateCurrency.value

      return validateCurrency.value && unit.value !== null
    })

    const setCurrency = (newCurrency: string) => {
      currency.value = newCurrency
    }

    const setUnit = (newUnit: string) => {
      unit.value = newUnit
    }

    const updateProduct = ({ id, product }: { id: string; product: any }) => {
      const index = products.value.findIndex((p) => p.id === id)
      if (index === -1) return
      products.value.splice(index, 1, { ...products.value[index], ...product })
    }

    const selectProduct = (product: any) => {
      const index = products.value.length - 1
      products.value.splice(index, 1, { ...products.value[index], ...product })
    }

    const addProduct = (newProduct: any) => {
      products.value.push(newProduct)
    }

    const removeProduct = (id: string) => {
      const index = products.value.findIndex((p) => p.id === id)
      products.value.splice(index, 1)
      if (products.value.length === 0) {
        products.value.push({
          id: '',
          key: 'default-product',
          name: '',
          completed: false,
          config: null,
          valid: false,
        })
      }
    }

    const unSelectProduct = (name: string) => {
      const index = products.value.findIndex((p) => p.name === name)
      if (index === -1) return
      products.value.splice(index, 1, {
        id: '',
        key: 'default-product',
        name: '',
        completed: false,
        config: null,
        valid: false,
      })
    }

    const completedCurrentProduct = () => {
      const index = products.value.length - 1
      if (index === -1) return
      products.value[index].completed = true
    }

    const loadProducts = (newProducts: any[]) => {
      products.value = newProducts
    }

    const reset = () => {
      currency.value = null
      unit.value = null
      products.value = [
        {
          id: '',
          key: 'default-product',
          name: '',
          completed: false,
          config: null,
          valid: false,
        },
      ]
    }

    return {
      unit,
      series,
      products,
      currency,
      isEditing,
      isValidProducts,
      validateProducts,
      validateCurrency,
      validProductsByUnits,
      validateUnit,
      setCurrency,
      setUnit,
      updateProduct,
      selectProduct,
      unSelectProduct,
      addProduct,
      removeProduct,
      loadProducts,
      reset,
      completedCurrentProduct,
    }
  },
)
