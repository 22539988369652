const getIcons = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const iconUrls = import.meta.glob('../assets/icons/**/*.svg')
  return Object.entries(iconUrls).reduce((acc, [key, value]) => {
    const name = key
      .split('../')[1]
      .replace('assets/icons/', '')
      .replace('.svg', '')
    return { ...acc, [name]: value }
  }, {})
}

const getIllustrations = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const illustrationUrls = import.meta.glob(
    '../assets/vector-illustrations/**/*.svg',
  )
  return Object.entries(illustrationUrls).reduce((acc, [key, value]) => {
    const name = key
      .split('../')[1]
      .replace('assets/vector-illustrations/', '')
      .replace('.svg', '')
    return { ...acc, [name]: value }
  }, {})
}

export default defineNuxtPlugin(() => {
  const icons = getIcons()
  const illustrations = getIllustrations()

  const getIconByName = (iconName: string): any => icons[iconName]

  const getIllustrationByName = (illustrationName: string): any =>
    illustrations[illustrationName]

  return {
    provide: {
      assets: {
        getIconByName,
        getIllustrationByName,
      },
    },
  }
})
