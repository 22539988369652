export const ONE_YEAR_DURATION = 365

export const SHOW_HOME_COPPER_MODAL = 'show_home_copper_modal'
export const BACKEND_VERSION_COOKIE = 'BACKEND_VERSION'
export const JWT_ACCESS_TOKEN = 'jwt_token'
export const REFRESH_TOKEN = 'refresh_token'
export const USER_INFO = 'user_info'
export const DEFAULT_OPTIONS = {
  path: '/',
  expires: new Date(Date.now() + ONE_YEAR_DURATION * 24 * 60 * 60 * 1000),
}

export const FRONTEND_BASE_URL_DEFAULT = 'https://staging.benchmarkminerals.com'
export const BASE_URL_DEFAULT = 'https://wp-staging.benchmarkminerals.com'
export const GRAPH_CDN_ENDPOINT_DEFAULT =
  'https://benchmark-www-staging.stellate.sh'
export const BENCHMARK_PRO_API_DEFAULT = 'https://qa.benchmarkminerals.com/v2'
export const SOURCE_URL_DEFAULT = 'https://source-staging.benchmarkminerals.com'
export const MAILCHIMP_NEWSLETTER_URL_DEFAULT =
  'https://benchmarkminerals.us9.list-manage.com/subscribe/post'

export const BENCHMARK_MINERALS_URLS = [FRONTEND_BASE_URL_DEFAULT]

export const FORMIDABLE_WEBSITE_URL_LABEL = 'website/url'

export const MEMBERSHIPS = 'memberships'
export const NON_PUBLISHED_POST_STATES = ['DRAFT', 'FUTURE']
export const PUBLISHED_POST_STATES = ['PUBLISH']

export const ENVIRONMENT = {
  LOCAL: 'local',
  STAGING: 'staging',
  UAT: 'uat',
  PRODUCTION: 'production',
} as const

export const DEVICE_SIZES = {
  DESKTOP_BIG: 'desktop_big',
  DESKTOP: 'desktop',
  DESKTOP_SMALL: 'desktop_small',
  TABLET: 'tablet',
  MOBILE: 'mobile',
} as const

export const IMAGE_SIZES = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
} as const
