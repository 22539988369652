import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import {
  SUBSCRIPTION_ALL,
  SUBSCRIPTION_ESG_COBALT,
  SUBSCRIPTION_ESG_BATTERY_EMISSIONS_ANALYSER,
  SUBSCRIPTION_ESG_LITHIUM,
  SUBSCRIPTION_ESG_NATURAL_GRAPHITE,
  SUBSCRIPTION_ESG_NICKEL,
  SUBSCRIPTION_ESG_SYNTHETIC_GRAPHITE,
  SUBSCRIPTION_ESG_GRAPHITE,
  SUBSCRIPTION_ESG_RARE_EARTHS,
  SUBSCRIPTION_FORECASTS_ANODE,
  SUBSCRIPTION_FORECASTS_LITHIUM_ION_BATTERIES,
  SUBSCRIPTION_FORECASTS_CATHODE,
  SUBSCRIPTION_FORECASTS_COBALT,
  SUBSCRIPTION_FORECASTS_LITHIUM,
  SUBSCRIPTION_FORECASTS_SILICON_ANODE,
  SUBSCRIPTION_FORECASTS_NATURAL_GRAPHITE,
  SUBSCRIPTION_FORECASTS_NICKEL,
  SUBSCRIPTION_FORECASTS_RECYCLING,
  SUBSCRIPTION_FORECASTS_SOLID_STATE,
  SUBSCRIPTION_FORECASTS_SYNTHETIC_GRAPHITE,
  SUBSCRIPTION_FORECASTS_RARE_EARTHS,
  SUBSCRIPTION_FORECASTS_MANGANESE,
  SUBSCRIPTION_FORECASTS_PHOSPHATE,
  SUBSCRIPTION_MARKET_ASSESSMENTS_GIGAFACTORY_ASSESSMENT,
  SUBSCRIPTION_MARKET_ASSESSMENTS_PRECURSOR_ASSESSMENT,
  SUBSCRIPTION_MARKET_ASSESSMENTS_SODIUM_ION_BATTERIES,
  SUBSCRIPTION_PRICE_ASSESSMENTS_ANODE,
  SUBSCRIPTION_PRICE_ASSESSMENTS_CATHODE,
  SUBSCRIPTION_PRICE_ASSESSMENTS_COBALT,
  SUBSCRIPTION_PRICE_ASSESSMENTS_LITHIUM,
  SUBSCRIPTION_PRICE_ASSESSMENTS_LITHIUM_ION_BATTERIES,
  SUBSCRIPTION_PRICE_ASSESSMENTS_NATURAL_GRAPHITE,
  SUBSCRIPTION_PRICE_ASSESSMENTS_NICKEL,
  SUBSCRIPTION_PRICE_ASSESSMENTS_SYNTHETIC_GRAPHITE,
  SUBSCRIPTION_SOURCE_SUBSCRIBER,
  SUBSCRIPTION_PRICE_ASSESSMENTS_BLACK_MASS,
  SUBSCRIPTION_PRICE_ASSESSMENTS_RARE_EARTHS,
  SUBSCRIPTION_FORECASTS_FLUORSPAR,
  SUBSCRIPTION_PRICE_ASSESSMENTS_ALL_PRICES,
  SUBSCRIPTION_FORECASTS_SODIUM_ION_BATTERIES,
  SUBSCRIPTION_FORECASTS_COPPER,
  SUBSCRIPTION_BRIEFING_COPPER,
  SUBSCRIPTION_SERVICE_COPPER,
} from '~/constants/services/constants'

interface Memberships {
  [key: string]: boolean | undefined
}

const DEFAULT_MEMBERSHIPS: Memberships = {
  [SUBSCRIPTION_ESG_COBALT]: undefined,
  [SUBSCRIPTION_ESG_BATTERY_EMISSIONS_ANALYSER]: undefined,
  [SUBSCRIPTION_ESG_LITHIUM]: undefined,
  [SUBSCRIPTION_FORECASTS_COPPER]: undefined,
  [SUBSCRIPTION_BRIEFING_COPPER]: undefined,
  [SUBSCRIPTION_SERVICE_COPPER]: undefined,
  [SUBSCRIPTION_ESG_NATURAL_GRAPHITE]: undefined,
  [SUBSCRIPTION_ESG_NICKEL]: undefined,
  [SUBSCRIPTION_ESG_SYNTHETIC_GRAPHITE]: undefined,
  [SUBSCRIPTION_ESG_GRAPHITE]: undefined,
  [SUBSCRIPTION_ESG_RARE_EARTHS]: undefined,
  [SUBSCRIPTION_FORECASTS_ANODE]: undefined,
  [SUBSCRIPTION_FORECASTS_LITHIUM_ION_BATTERIES]: undefined,
  [SUBSCRIPTION_FORECASTS_SODIUM_ION_BATTERIES]: undefined,
  [SUBSCRIPTION_FORECASTS_CATHODE]: undefined,
  [SUBSCRIPTION_FORECASTS_COBALT]: undefined,
  [SUBSCRIPTION_FORECASTS_LITHIUM]: undefined,
  [SUBSCRIPTION_FORECASTS_SILICON_ANODE]: undefined,
  [SUBSCRIPTION_FORECASTS_NATURAL_GRAPHITE]: undefined,
  [SUBSCRIPTION_FORECASTS_NICKEL]: undefined,
  [SUBSCRIPTION_FORECASTS_SOLID_STATE]: undefined,
  [SUBSCRIPTION_FORECASTS_SYNTHETIC_GRAPHITE]: undefined,
  [SUBSCRIPTION_FORECASTS_RECYCLING]: undefined,
  [SUBSCRIPTION_FORECASTS_RARE_EARTHS]: undefined,
  [SUBSCRIPTION_FORECASTS_MANGANESE]: undefined,
  [SUBSCRIPTION_FORECASTS_PHOSPHATE]: undefined,
  [SUBSCRIPTION_FORECASTS_FLUORSPAR]: undefined,
  [SUBSCRIPTION_PRICE_ASSESSMENTS_LITHIUM]: undefined,
  [SUBSCRIPTION_PRICE_ASSESSMENTS_COBALT]: undefined,
  [SUBSCRIPTION_PRICE_ASSESSMENTS_NICKEL]: undefined,
  [SUBSCRIPTION_PRICE_ASSESSMENTS_NATURAL_GRAPHITE]: undefined,
  [SUBSCRIPTION_PRICE_ASSESSMENTS_SYNTHETIC_GRAPHITE]: undefined,
  [SUBSCRIPTION_PRICE_ASSESSMENTS_LITHIUM_ION_BATTERIES]: undefined,
  [SUBSCRIPTION_PRICE_ASSESSMENTS_ANODE]: undefined,
  [SUBSCRIPTION_PRICE_ASSESSMENTS_CATHODE]: undefined,
  [SUBSCRIPTION_SOURCE_SUBSCRIBER]: undefined,
  [SUBSCRIPTION_MARKET_ASSESSMENTS_GIGAFACTORY_ASSESSMENT]: undefined,
  [SUBSCRIPTION_MARKET_ASSESSMENTS_PRECURSOR_ASSESSMENT]: undefined,
  [SUBSCRIPTION_MARKET_ASSESSMENTS_SODIUM_ION_BATTERIES]: undefined,
  [SUBSCRIPTION_PRICE_ASSESSMENTS_BLACK_MASS]: undefined,
  [SUBSCRIPTION_PRICE_ASSESSMENTS_RARE_EARTHS]: undefined,
  [SUBSCRIPTION_PRICE_ASSESSMENTS_ALL_PRICES]: undefined,
  [SUBSCRIPTION_ALL]: undefined,
}

export const useMembershipsStore = defineStore('memberships', () => {
  const state = ref<Memberships>(DEFAULT_MEMBERSHIPS)

  const activeMemberships = computed(() =>
    Object.keys(state.value).filter((key) => !!state.value[key]),
  )

  const activePriceDashboardMembership = computed(() =>
    activeMemberships.value.some(
      (key) => key.includes('pricing') || key.includes('price-dashboard'),
    ),
  )

  const allSubscriptions = computed(() => state.value.allSubscriptions)

  const setMembershipStatus = ({
    category,
    isActive,
  }: {
    category: string
    isActive: boolean
  }) => {
    state.value[category] = isActive
  }

  const setActiveMembershipStatuses = (memberships: string[]) => {
    Object.keys(state.value).forEach((_membership) => {
      if (_membership === SUBSCRIPTION_ALL) return
      if (_membership === SUBSCRIPTION_ESG_NATURAL_GRAPHITE) {
        state.value[_membership] =
          memberships.includes(SUBSCRIPTION_ESG_GRAPHITE) ||
          memberships.includes(SUBSCRIPTION_ESG_NATURAL_GRAPHITE)
      } else if (_membership === SUBSCRIPTION_ESG_SYNTHETIC_GRAPHITE) {
        state.value[_membership] =
          memberships.includes(SUBSCRIPTION_ESG_GRAPHITE) ||
          memberships.includes(SUBSCRIPTION_ESG_SYNTHETIC_GRAPHITE)
      } else {
        state.value[_membership] = memberships.includes(_membership)
      }
    })
  }

  const setAllSubscriptions = (allSubscriptions: any) => {
    state.value.allSubscriptions = allSubscriptions
  }

  const clearKeys = () => {
    Object.keys(state.value).forEach((key) => (state.value[key] = undefined))
  }

  const isMembershipActive = (membershipName: string) => {
    return Boolean(state.value[membershipName])
  }

  const isMembershipsActive = (membershipNames: string[]) => {
    return membershipNames.some((membershipName) => state.value[membershipName])
  }

  const getSubscription = (subscription: string) =>
    Boolean(state.value[subscription])

  return {
    state,
    activeMemberships,
    activePriceDashboardMembership,
    allSubscriptions,
    setMembershipStatus,
    setActiveMembershipStatuses,
    setAllSubscriptions,
    clearKeys,
    isMembershipActive,
    isMembershipsActive,
    getSubscription,
  }
})
